import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
/* import Data from "../data/data.js"; */

import Navbar from "../components/Navbar";
import { Helmet } from "react-helmet";
import HeroSection from "../components/HeroSection";
import ProductBox from "../components/ProductBox";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import WhatsappButton from "../components/WhatsappButton";

import { slugify } from "../utils/helpers";

import 'bootstrap/dist/css/bootstrap.min.css';

/* import heroImg from "../images/productos_header.jpg"; */

import "../scss/main.scss";

const ProductosPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProductsPageTemplate {
        nodes {
          hero_description_es {
            hero_description_es
          }
          hero_title_es
          imageHero {
            file {
              url
            }
          }
        }
      }
      allContentfulProduct(sort: { fields: title_es, order: ASC }) {
        nodes {
          description_es {
            raw
          }
          title_es
          video_id
        }
      }
    }
  `);

  const pageTitle = "Productos | Virtual Ed Global"
  const heroTitle = data.allContentfulProductsPageTemplate.nodes[0].hero_title_es;
  const heroDescription = data.allContentfulProductsPageTemplate.nodes[0].hero_description_es.hero_description_es;
  const heroImg = data.allContentfulProductsPageTemplate.nodes[0].imageHero.file.url;

  return (
    <main>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={heroTitle} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:site_name" content="Virtual Ed Global" />
        <meta property="og:url" content="https://virtualedglobal.com/productos" />
        <meta property="og:description" content={heroDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={heroImg} />
        <meta name="twitter:image" content={heroImg} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={heroDescription} /> 
      </Helmet>

      <Navbar />

      <div className="page-wrapper">
        <HeroSection image={heroImg} title={heroTitle} description={heroDescription} />
        <section className="product-boxes">
          <div className="container">
            <div className="row">
              {data.allContentfulProduct.nodes.map((item) => {
                return (
                  <ProductBox
                    title={item.title_es}
                    description={item.description_es}
                    id={slugify(item.title_es)}
                    key={item.title_es}
                    video={item.video_id}
                    see_more_label="Ver más"
                    see_less_label="Ver menos"
                    btn_video_label="Ver Video"
                  />
                );
              })}
            </div>
          </div>
        </section>

        {/* Contact */}
        <section id="contacto">
          <Contact
            title={
              <p>
                <strong>Aquí estamos para conversar sobre Educación Superior.</strong> Esperamos tus consultas o
                comentarios.
              </p>
            }
            button_label="Conversemos"
          />
        </section>
      </div>

      <WhatsappButton />
      <Footer />
    </main>
  );
};

export default ProductosPage;
